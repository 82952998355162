import React from "react"
import styled, {ThemeProvider} from "styled-components"
import {Link, useStaticQuery, graphql} from "gatsby"
import Img from 'gatsby-image'

import Container from "../components/Layout/Container"
import theme from "../styles/theme"
import GlobalStyle from "../styles/GlobalStyles"

const Style = styled.div`
  background-color: ${props => props.theme.bg};
  color: ${props => props.theme.text};
  height: 100vh;
`

const RowContainer = styled(Container)`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  *{
    margin-bottom: 1rem;
  }
  a{
    color: ${props => props.theme.text};
    font-size: 2rem;
  }
`

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
        file(relativePath: {regex: "/404/"}) {
            childImageSharp {
                fixed(width: 450) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
  `)

  return(
    <ThemeProvider theme={theme}>
      <GlobalStyle/>
      <Style>
        <RowContainer>
          <Img fixed={data.file.childImageSharp.fixed}/>
          <h1>hola, hola, 404</h1>
          <p>nie ma takiej podstrony</p>
          <Link to='/'>wróc do strony głównej</Link>
        </RowContainer>
      </Style>
    </ThemeProvider>
)}

export default NotFoundPage